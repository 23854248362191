import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

class Election2020Page extends React.Component {
  render() {
    const payasyougo = this.props.data.payasyougo
    const voterspass = this.props.data.voterspass
    const communitythree = this.props.data.communitythree
    const savethedate = this.props.data.savethedate
    return (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1">2022 Directors Election</h1>
              <p>See documents and articles related to the 2022 directors election.</p>
              <hr />
              <div className="news-preview-container">

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Westlake Website Candidate Filing Notice-2022.pdf">NOTICE REGARDING CANDIDATE FILING PERIOD<br />AVISO REFERENTE AL PERIODO DE PRESENTACIÓN DE CANDIDATURA<br />THÔNG BÁO VỀ GIAI ĐOẠN NỘP ĐƠN XIN TRANH CỬ CỦA ỨNG CỬ VIÊN<br />關於候選人申請提交期限的通知</a>
                    <br /><small className="accent-color">December 16, 2021</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Notice of Appointed Agent - 2020">View</a>
                  </div>
                </div>

              </div>
              <div className="section">
                <hr />
              <p><strong>English</strong><br />
              If you need this election document provided in Chinese, Vietnamese, or Spanish, please send us a message requesting the translated document using the <Link to="/contact">“Contact Us”</Link> link on this website.</p>

              <p><strong>Spanish</strong><br />
              Si necesita que le entreguen este documento sobre la elección en chino, vietnamita o español, envíenos un mensaje solicitando el documento traducido a través del enlace <Link to="/contact">“Contact Us”</Link> de este sitio web.</p>

              <p><strong>Vietnamese</strong><br />
              Nếu quý vị cần tài liệu bầu cử này bằng tiếng Hoa, tiếng Việt, hoặc tiếng Tây ban nha, vui lòng gửi thư yêu cầu cung cấp bản chuyển ngữ bằng cách sử dụng đường liên kết <Link to="/contact">"Contact Us"</Link> trên website này.</p>

              <p><strong>Chinese</strong><br />
              如果您需要這份選舉文件的中文、越南語或西班牙語版本，請使用本網站上的<Link to="/contact">「Contact Us」</Link>鏈接，向我們索取翻譯文本。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}
}

export default Election2020Page

export const election2020PageQuery = graphql`
  query Election2022Query {
    payasyougo: imageSharp(original: {src: {regex: "/bond-financing-vs-pay-as-you-go-ENG/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    voterspass: imageSharp(original: {src: {regex: "/DSC_2977/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    communitythree: imageSharp(original: {src: {regex: "/community3/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    savethedate: imageSharp(original: {src: {regex: "/save-the-date-oct-15/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
  }
`